@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@200;300;400;500;600;700&family=Roboto:wght@400;500;700&display=swap');

.sale-invoice {
  font-family: 'Roboto Mono', monospace;
  color: #000000;

  .sale-print-logo {
    height: 80px;
  }

  .print-text {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .print-price {
    justify-content: flex-end;
    display: flex;
    align-items: center;
  }

  .button-section {
    margin-top: 15px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;

    .button-child {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 15px;
    }

  }

  .print-button {
    height: 30px;
    background: black;
    color: white;
  }

  .header-p {
    text-align: center;
    margin-bottom: 0;
    font-size: 18px;
    line-height: 20px;
  }

  .header-p-bold {
    text-align: center;
    margin-bottom: 15px;
    margin-top: 15px;
    font-size: 30px;
    line-height: 20px;
    font-weight: bold;
  }

  .receipt-text {
    border: 1px solid;
    width: 100px;
    margin: 15px auto 5px auto;
    text-align: center;
  }

  .sale-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 9px;
    padding: 10px;

    p {
      display: flex;
      font-size: 12px;
      justify-content: space-between;
      margin: 0;
    }

    .served-info {
      display: flex;
      justify-content: space-between;

      p {
        display: flex;
        flex-direction: column;
        text-align: left;
        margin: 0;
      }

      .right {
        text-align: right;
      }
    }
  }

  .sale-info-sale-print {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 9px;
    padding: 10px;

    .main {

      display: flex;
      column-gap: 2px;
    }

    .heading {
      display: flex;
      font-size: 15px;
      justify-content: space-between;
      margin: 0;

    }

    .served-info {
      display: flex;
      justify-content: space-between;

      p {
        display: flex;
        flex-direction: column;
        text-align: left;
        margin: 0;
      }

      .right {
        text-align: right;
      }
    }
  }


  table {
    font-family: 'Roboto Mono', monospace;
    color: #000000;
    border-collapse: collapse;
    width: 100%;
  }

  td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  .table-footer {
    .table-footer-content {
      margin: 20px 0;

      p {
        display: flex;
        justify-content: space-between;
        margin: 2px 0;

        .bordered-span {
          width: 70%;
          text-align: right;
          padding-right: 2px;
          border: 1px solid;
        }

        .bordered-span-down {
          width: 70%;
          text-align: right;
          padding-right: 2px;
          border-bottom: 1px solid;
        }

        .normal-span {
          padding-right: 2px;
        }
      }
    }

    .warning-info {
      background-color: #C3FCFF;
      text-align: center;
    }

    .additional-info {
      padding: 10px;
      text-align: center;
      border: 1px solid #000000;
      line-height: 20px;
    }
  }

}

.pos-sale-invoice {
  font-family: 'Roboto Mono', monospace;
  color: #000000;
  width: 302.36px;

  .header-p {
    font-size: 20px;
    margin-bottom: 0;
    text-align: center;
  }

  .shop-name {
    font-family: 'Roboto Mono', monospace;
    color: #000000;
    font-size: 25px;
    margin-bottom: 0;
    text-align: center;
  }

  .receipt-text {
    font-family: 'Roboto Mono', monospace;
    color: #000000;
    border: 1px solid;
    width: 100px;
    margin: 15px auto 5px auto;
    text-align: center;
  }

  .address {
    font-size: 12px;
    margin: 0;
  }

  .sale-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 9px;
    padding: 10px;

    p {
      display: flex;
      font-size: 9px;
      justify-content: space-between;
      margin: 0;
    }

    .served-info {
      display: flex;
      justify-content: space-between;

      p {
        display: flex;
        flex-direction: column;
        text-align: left;
        margin: 0;
      }

      .right {
        text-align: right;
      }
    }
  }

  table {
    font-family: 'Roboto Mono', monospace;
    color: #000000;
    border-collapse: collapse;
    width: 100%;
    font-size: 9px;

    .table-footer {
      .table-footer-content {
        margin: 20px 0;
        font-size: 9px;

        p {
          display: flex;
          justify-content: space-between;
          margin: 2px 0;

          .bordered-span {
            width: 70%;
            text-align: right;
            padding-right: 2px;
            border: 1px solid;
          }

          .normal-span {
            padding-right: 2px;
          }
        }
      }

      .warning-info {
        background-color: #C3FCFF;
        text-align: center;
      }

      .additional-info {
        padding: 10px;
        text-align: center;
        border: 1px solid #000;
      }
    }
  }

  td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 2px;
  }

}