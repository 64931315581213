@import "./common.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

/* This will work on Firefox */
* {
  scrollbar-width: 10px;
  //scrollbar-width: thin;
  scrollbar-color: darkgray #dddddd;
}

/* Target's on Chrome, Edge, and Safari */

.ant-menu {
  &::-webkit-scrollbar {
    height: 10px !important;
    width: 10px !important;
  }
}

//*::-webkit-scrollbar {
//  width: 10px;
//}

*::-webkit-scrollbar-track {
  background: #dddddd;
}

*::-webkit-scrollbar-thumb {
  background-color: darkgray;
}

.ant-table {

  .ant-table-body {
    scrollbar-width: 10px;
    //scrollbar-width: thin;
    scrollbar-color: darkgray #dddddd;

    &::-webkit-scrollbar {
      height: 10px;
      //height: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #dddddd;
    }

    &::-webkit-scrollbar-thumb {
      background-color: darkgray;
    }

  }

}

/* ============  Start Custom CSS =========== */

/* APP layout */
.app-wrapper {
  @include fullDisplay;
  @include flexContentCenter;

  .app-page {
    margin: 5px 16px;

    .custom-page-header {
      padding: 5px 16px;
    }

    .page-header {
      margin-bottom: 2px;
    }

    .page-content {
      overflow-y: auto;
      height: calc(100vh - 158px);
      overflow-x: hidden;
      background: #fff;
      margin-bottom: 10px;
      padding: 16px 24px;
    }
  }
}

// selected row color of table
.table-selected-color {
  background-color: #40af0947;
}

// table hover off
.ant-table-tbody {
  > tr.ant-table-row {
    &:hover {
      > td {
        background: none !important;
      }
    }
  }
}

// keyboard btn style
@mixin parent-icon-wrapper {
  display: inline-grid;
  border: 1px solid #dc3545;
  padding: 5px;
  cursor: pointer;
}

.action-wrapper {
  display: flex;
  justify-content: center;

  .icon-wrapper {
    display: inline-grid;
    margin-right: 5px;
    border: 1px solid #40A9FF;
    padding: 5px;
    cursor: pointer;

    .enter-icon {
      border: 1px solid #40A9FF;
      margin-bottom: 7px;
      padding: 0 3px;
      color: #40A9FF;
    }

    .edit-icon {
      color: #40A9FF;
      font-size: 18px;
    }
  }

  .delete-icon-wrapper {
    @include parent-icon-wrapper;
    margin-right: 5px;

    .esc-icon {
      border: 1px solid #dc3545;
      font-size: 11px;
      margin: 0 0 5px 0;
      font-weight: 500;
      padding: 0 3px;
      color: #dc3545;
    }

    .delete-icon {
      color: #dc3545;
      font-size: 18px;
    }
  }

  .ins-icon-wrapper {
    @include parent-icon-wrapper;
    margin-right: 5px;
    border: 1px solid #28a745;

    .ins-icon {
      border: 1px solid #28a745;
      font-size: 11px;
      margin: 0 0 5px 0;
      font-weight: 500;
      padding: 0 3px;
      color: #28a745;
    }

    .view-icon {
      color: #28a745;
      font-size: 18px;
    }
  }

  .print-icon-wrapper {
    @include parent-icon-wrapper;
    border: 1px solid gray;
    margin-right: 5px;

    .p-icon {
      border: 1px solid gray;
      font-size: 11px;
      margin: 0 0 5px 0;
      font-weight: 500;
      padding: 0 6px;
      color: gray;
    }

    .printer-icon {
      color: gray;
      font-size: 18px;
    }
  }

  .checked-icon-wrapper {
    @include parent-icon-wrapper;
    border: 1px solid #4C51A1;

    .esc-icon {
      border: 1px solid #4C51A1;
      font-size: 11px;
      margin: 0 0 5px 0;
      font-weight: 500;
      padding: 0 3px;
      color: #4C51A1;
    }

    .printer-icon {
      color: #4C51A1;
      font-size: 18px;
    }
  }
}

.ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 10px !important;
}

// amount input right align
.input-text-align-right {
  input {
    text-align: right;
    padding-right: 25px;
  }
}

// checkbox style
.ant-checkbox {
  background-color: #40A9FF;
  padding: 4px;
}

// focus color
.ant-btn{
  height: 30px;
}

.ant-btn:focus {
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 0, 0, .3);
}

// modal close focus
.ant-modal-close:focus {
  box-shadow: 0 0 0 2px rgba(255, 0, 0, .3);
}

.report-print-button {
  height: 30px;
  background: black;
  color: white;
}
