.pdf-table {

  width: 100%;
  //width: 1080px;

  .product-product-name-td, .product-display-name-td {
    height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .product-info-sl-td, .quantity-td, .price-td, .total-td {
    height: 30px;
  }

  tbody {

    .empty-cell {
      td {
        border: transparent;
      }
    }

  }

  .branding-header-tr {
    height: 165px;

    .branding-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .logo {
        width: 230px;
        padding: 10px;

        img {
          width: 100%;
        }

      }

      .company-info {
        width: 800px;
        padding: 10px;

        .company-name {
          font-size: 24px;
          margin: 0 2px;
        }

        h4 {
          overflow: hidden;
          font-size: 14px;
          line-height: 1.6;
          margin: 5px 0;
        }

        .double-line-ellipsis {
          display: -webkit-box;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }

        .single-line-ellipsis {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .m-t-8 {
          margin-top: 8px;
        }

        .m-t-10 {
          margin-top: 10px;
        }

        .m-b-8 {
          margin-bottom: 8px;
        }

        .m-b-10 {
          margin-bottom: 10px;
        }

      }
    }

  }

  .invoice-details-header-tr {
    height: 100px;
    //height: 120px;

    .invoice-details-header {

      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;

      .invoice-info {
        width: 530px;
        padding: 0 10px;
        //padding: 10px;
      }

      .customer-info {
        width: 530px;
        padding: 0 10px;
        //padding: 10px;
        border-left: 1px solid #EDEDED;
      }

      .info-row {
        display: flex;
        align-items: center;
        margin: 2px 0;
        //margin: 0 0 8px 0;

        b {
          display: inline-block;
          width: 150px;
        }
      }

    }

  }

  .sl-td-header {
    width: 40px;
    text-align: center;
    padding: 10px 0;
  }

  .product-name-td-header, .display-name-td-header {
    padding-left: 5px;
  }

  .quantity-td-header, .price-td-header, .total-td-header {
    //width: 60px;
    padding: 10px 5px 10px 0;
    text-align: right;
  }

  .sl-col {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    width: 40px;
    padding: 5px;
  }

  .quantity-col, .price-col, .total-col {
    //overflow: hidden;
    //text-overflow: ellipsis;
    //white-space: nowrap;
    text-align: right;
    //width: 60px;
    padding: 5px;
  }

  .double-name-col {
    width: 380px;
    padding: 5px;
    line-height: 1.6;
  }

  .single-name-col {
    width: 770px;
    padding: 5px;
    line-height: 1.6;
  }

  .price-summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #EDEDED;

    .in-word {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 530px;
      padding: 8px 10px;
      text-transform: capitalize;
    }

    .summary {
      width: 530px;
      padding: 10px;
      border-left: 1px solid #EDEDED;
      font-size: 13px;

      .price-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 8px 0;

        b {
          display: inline-block;
          width: 150px;
        }

        span {
          text-align: right;
        }
      }

    }

  }

  .signature-content {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    height: 100px;

    .left-signature, .right-signature {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 400px;
      padding: 10px;
      border-top: 1px solid #EDEDED;
    }

  }

  .footer-info {
    padding: 10px;
    text-align: center;

    p {
      padding: 20px;
      font-size: 18px;
      line-height: 1.6;
      background-color: #EDEDED;
    }

  }

  .bold {
    font-weight: bold;
  }

  table, th, td {
    border: 1px solid #000000;
    border-collapse: collapse;

    .top-header-row {
      td {
        border: 2px solid green;
      }
    }
  }

  th, td {
    text-align: left;
    font-size: 14px;
  }

  th {
    background-color: #FAFAFA;
    font-weight: 600;
  }

  td {
    color: #3A3A3A;
  }

}
