.payment-cancellation-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .ant-result {
    max-width: 400px;
    text-align: center;
  }
}