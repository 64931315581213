00509B
.sale-invoice-a4 {

  margin: 0 200px;

  .button-section {
    margin-top: 15px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;

    .button-child {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 15px;
    }

  }

  .print-button {
    height: 30px;
    background: black;
    color: white;
  }

}